import React from 'react';
import About from '../../components/about/About.jsx';
import Footer from '../../components/footer/Footer.jsx';
import { Card, Typography, Button, Box } from '@mui/material';
import GloboContacto from '../../components/globoContacto/GloboContacto.jsx';
import Loader from '../../components/loader/Loader.jsx';
import NavBar from '../../components/navBar/NavBar.jsx';
import Promotion from '../../components/promotion/Promotion.jsx';
import SocialNetwork from '../../components/socialNetwork/SocialNetwork.jsx';
import StampTop from '../../components/stamp/StampTop.jsx';
import StampBottom from '../../components/stamp/StampBottom.jsx';
import VideoHome from '../../components/videoHome/VideoHome.jsx';
import { useSelector } from 'react-redux';
import { getSales } from '../../utils/products/products.js';
import './NewHomeMarket.Module.css';
import CarouselImg from '../../components/carousel/Carousel.jsx';
import CarouselOfertas from '../../components/carousel/CarouselOfertas.jsx';
import { Toaster, toast } from 'react-hot-toast';
import axios from 'axios';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { isEditMode } from '../../utils/users/users.js';
import { useMediaQuery } from 'react-responsive';
import Promotion2 from '../../components/promotionShop/Promotion2.jsx';
import CarouselGoInfo from '../../components/carouselGoInfo/CarouseGolInfo.jsx';
import CarouselMarcas from '../../components/carouselMarcas/CarouselMarcas.jsx';
import WhatsappButton from '../../components/whatsappButton/WhatsappButton.jsx';
import gangas from "../../assets/images/beautigangas.png"
import InstagramIcon from '@mui/icons-material/Instagram';

function NewHomeMarket() {
	const bannerText = ["APROVECHA NUESTRAS BEAUTIGANGAS", "CONOCE NUESTRAS PROMOS BANCARIAS","DESCUBRI NUESTRO BEST SELLER DEL MES", "HASTA 3 CUOTAS SIN INTERES"]
	const isMobile = useMediaQuery({ maxWidth: 700 });
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	const user = useSelector((state) => state.user.userInfo);
	const [sales, setSales] = React.useState([]);
	const [loader, setLoader] = React.useState(true);
	const [image, setImage] = React.useState('');
	const isSuperAdmin = user.role === 'SUPERADMIN_ROLE';
	const [renderKey, setRenderKey] = React.useState(0);
	const [currentBanner, setCurrentBanner] = React.useState(0)

	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1,
			slidesToSlide: 1,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1,
			slidesToSlide: 1,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1,
		},
	};

	React.useEffect(() => {
		const cargarImagenes = async () => {
			const { data } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=FONDO_HOME`
			);
			setImage(data.imagenes[0]);
			setLoader(false);
		};
		cargarImagenes();
	}, [renderKey]);

// 	React.useEffect(() => {
//     const interval = setInterval(() => {
  
//             setCurrentBanner((prevBanner) => (prevBanner + 1) % bannerText.length);
    
//     }, 3000); // Tiempo antes de cambiar al siguiente banner

//     return () => clearInterval(interval);
// }, []);




	function handleOpenWidget() {
		try {
			const widgetCloudinary = window.cloudinary.createUploadWidget(
				{
					cloudName: 'dlblszxyo',
					uploadPreset: 'laBeautiqueria',
				},
				async (err, result) => {
					if (!err && result && result.event === 'success') {
						const url = result.info.secure_url;

						await axios.put(
							`${process.env.REACT_APP_BACK_URL}/api/imagenes/${image._id}?url=${url}`,
							'',
							config
						);

						setImage({ ...image[0], url });
						setRenderKey((prevKey) => prevKey + 1);
					}
				}
			);

			widgetCloudinary.open();
		} catch (error) {
			toast.error('Problema con la carga de la imagen', error);
		}
	}



	React.useEffect(() => {
		toast.remove();
		async function getOfertas() {
			setSales(await getSales());
			setLoader(false);
		}
		getOfertas();
		window.addEventListener('actualizar-producto', getOfertas);
		return () => {
			window.removeEventListener('actualizar-producto', getOfertas);
		};
	}, []);

	// React.useEffect(() => {
	// 	// Ejecuta la función para comprobar el modo de edición
	// 	isEditMode(user && user);
	// }, []);

	return (
		<>
			{loader ? (
				<Loader />
			) : (
				<div className='fondoHome' style={{ backgroundImage: ``, maxWidth:"100vw", overflowX:"hidden" }}>
					<Box className='headerMarket'>
						<NavBar />
					</Box>
					<Box className='mainMarket'>
						<>
							<Toaster
								position='bottom-left'
								reverseOrder={false}
								toastOptions={{ className: 'fondoToast' }}
							/>
							<section className='sectionCarousel'>
								<CarouselImg />
							</section>
							{isSuperAdmin && (
								<div className='fondohome' onClick={() => handleOpenWidget()}>
									<i className='bi bi-pencil'></i>
									cambiar fondo
								</div>
							)}
						
							{sales && sales.productos?.length > 0 && (
								<section>
									<CarouselOfertas sales={sales} />
								</section>
							)}




					

							
							<section className='sectionStampMarket'>
								<StampTop />
							</section>
							<section className='sectionPromotion2'>
								<Promotion2 />
							</section>
							{/* <section  className='sectionAboutMarket'>
								<About />
							</section> */}
							<section  style={{margin:"0px"}} className='sectionStampMarket'> 
								<StampBottom />
							</section>
							<section className='sectionPromotion'>
								<Promotion />
							</section>
							{/* <section  style={{margin:"0px"}}>  */}
								<CarouselMarcas/>
							{/* </section> */}
							<section style={{marginTop:"10vh"}} className='socialNetworks'>
								<SocialNetwork />
							</section>

							<section style={{marginTop:"10vh", marginBottom:"10vh"}}>
								<CarouselGoInfo />
							</section>
						
							{/* <GloboContacto /> */}
							<WhatsappButton />
								{/*<VideoHome />*/}
						</>
					</Box>
					{/* <h2 style={{marginTop:isMobile && "10vh", justifySelf:"center"}} className='py-5 tituloOfertas'>Seguinos en Instagram </h2> */}

<Box style={{

									width:"100vw", 
									height:!isMobile?"50vh":"auto",
									maxHeight:"100vh",
									justifyContent:"space-around",
									 marginTop: isMobile ? "-10vh":"5vh",
									 marginBottom: isMobile ? "10vh":"5vh",
									display:"flex",
									flexDirection:isMobile?"column-reverse":"row-reverse",
									// justifyContent:"center",
									alignItems:"center"
									}}>

																	
					<Box
					style={{
						width:!isMobile?"30%":"100%",
						height:isMobile?"50vh":"100%",
		
						justifyContent:"space-around",
						margin: isMobile ? '0px 0' : '250px 0',
						display:"flex",
						flexDirection:"column",
						gap:"10px",
						// justifyContent:"center",
						alignItems:"center"
									}}>


					<Box 
									onClick={() => window.open("https://www.instagram.com/marketbylabeautiqueria/", "_blank")}
									sx={{	
								
												width:isMobile?"100vw":"100%", 
												height:!isMobile?"55px":"45px", 
												backgroundColor:"#23563F", 
												display:"flex", 
												justifyContent:"center", 
												alignItems:"center",
												cursor:"pointer",
												gap:"5px",
												transition: "all 0.3s ease",
												boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
								
												"&:hover": {
														backgroundColor: "#193d2d",
														boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.2)",
												},
								
												"&:active": {
														transform: "scale(0.95)",
												}
												}}>
		
									<Typography  sx={{color:"white", fontFamily:"Poppins", fontSize:isMobile?"12px":"14px", fontWeight:"400"}}>
										@marketbylabeautiqueria - #beBeautiquera
									</Typography>
								</Box>
						<iframe 
							src="https://snapwidget.com/embed/1088111" 
							allowtransparency="true" 
							frameBorder="0" 
							scrolling="yes" 
							style={{border:"none", overflow:"hidden", width:"100%", height:!isMobile?"100%":"30vh",}} 
							title="Posts from Instagram">
							
							
						</iframe>
						<Box 
									
									sx={{	
					
												width:isMobile?"100vw":"100%", 
												height:!isMobile?"55px":"45px", 
												backgroundColor:"#23563F", 
												display:"flex", 
												justifyContent:"center", 
												alignItems:"center",
												cursor:"pointer",
												gap:"5px",
												transition: "all 0.3s ease",
												boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",

												}}>
													<InstagramIcon sx={{fill:"white"}}/>
									<Typography  sx={{color:"white", fontFamily:"Poppins", fontSize:isMobile?"12px":"14px"}}>
										Market by La Beautiquería
									</Typography>
								</Box>

					</Box>

												
					<Box
					style={{
						width:!isMobile?"30%":"100%",
						height:"100%",
									justifyContent:"space-around",
									margin: isMobile ? '100px 0' : '250px 0',
									display:"flex",
									flexDirection:"column",
									gap:"10px",
									// justifyContent:"center",
									alignItems:"center"
									}}>

<Box 
								
									sx={{	
										// marginTop:"5vh",
										width:isMobile?"100vw":"100%", 
										height:!isMobile?"55px":"45px", 
										backgroundColor:"#F485C7", 
										display:"flex", 
										justifyContent:"center", 
										alignItems:"center",
										cursor:"pointer",
										gap:"5px",
			
										transition: "all 0.3s ease",
										boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
						
										
										}}>
										
									<Typography className='goBeautiGangas' sx={{color:"white", fontFamily:"Poppins", fontSize:isMobile?"12px":"14px"}}>
									Conocé nuestros servicios - #beBeautiquera
									</Typography>
								</Box>

					
						<iframe 
							src="https://snapwidget.com/embed/1088113" 
							allowtransparency="true" 
							frameBorder="0" 
							scrolling="yes" 
							style={{border:"none", overflow:"hidden", width:"100%", height:!isMobile?"100%":"30vh",}} 
							title="Posts from Instagram">
							
						</iframe>
						<Box 
									onClick={() => window.open("https://www.instagram.com/labeautiqueria/", "_blank")}
									sx={{	
										// marginTop:"5vh",
										width:isMobile?"100vw":"100%", 
										height:!isMobile?"55px":"45px", 
										backgroundColor:"#F485C7", 
										display:"flex", 
										justifyContent:"center", 
										alignItems:"center",
										cursor:"pointer",
										gap:"5px",
			
										transition: "all 0.3s ease",
										boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
						
										"&:hover": {
												backgroundColor: "#e070b3",
												boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.2)",
										},
						
										"&:active": {
												transform: "scale(0.95)",
										}
										}}>
												<InstagramIcon sx={{fill:"white"}}/>
									<Typography className='goBeautiGangas' sx={{color:"white", fontFamily:"Poppins", fontSize:isMobile?"12px":"14px"}}>
									La Beautiquería
									</Typography>
								</Box>

					</Box>


</Box>

					<footer>
						<Footer />
					</footer>
				</div>
			)}
		</>
	);
}

export default NewHomeMarket;
