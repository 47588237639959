/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Card from '../../components/card/Card';
import Footer from '../../components/footer/Footer';
import GloboContacto from '../../components/globoContacto/GloboContacto';
import Loader from '../../components/loader/Loader';
import NavBar from '../../components/navBar/NavBar';
import Pagination from '../../components/pagination/Pagination';
import { useParams, useNavigate } from 'react-router-dom';
import { searchProducts, ordenarProductos } from '../../utils/products/products';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { getProductsByCategories, getFilters } from '../../utils/products/products';
import { Toaster, toast } from 'react-hot-toast';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@react-hook/media-query';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import './Catalogue.Module.css';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';

function Catalogue() {
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};
	const user = useSelector((state) => state.user.userInfo);

	const isMobile = useMediaQuery('(max-width: 768px)');

	const [scrollPosition, setScrollPosition] = React.useState(0);

	const [filtros, setFiltros] = React.useState(null);

	const [image, setImage] = React.useState('');
	const isSuperAdmin = user.role === 'SUPERADMIN_ROLE';
	const [renderKey, setRenderKey] = React.useState(0);

	const { id } = useParams();

	const searchParam = new URLSearchParams(window.location.search).get('termino');

	const nombreParam = new URLSearchParams(window.location.search).get('nombre');

	const marcaParam = new URLSearchParams(window.location.search).get('marca');

	const querySub = new URLSearchParams(window.location.search).get('subcategoria');

	const queryCat = new URLSearchParams(window.location.search).get('categoria');

	const nombre = decodeURIComponent(nombreParam);
	const navigate = useNavigate();

	const [loader, setLoader] = React.useState(false);

	//Search
	const [termino, setTermino] = React.useState('');

	function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


	const handleSearch = (e) => {
		setTermino(e.target.value);
		// setDesde(0)
	};

	//Paginado
	const [products, setProducts] = React.useState([]);
	const [paginaActual, setPaginaActual] = React.useState(1);
	const [desde, setDesde] = React.useState(0);
	const productosPorPagina = 16;
	const limit = 16

	const paginado = (numeroPagina) => {
		setLoader(true);
		setPaginaActual(numeroPagina);
		setDesde(numeroPagina * 16 - 16);
		window.scrollTo(0, 0);
		setTimeout(() => {
			setLoader(false); // Ocultar el loader después de cargar los productos
		}, 500); // Puedes ajustar el tiempo según tu necesidad

		// Actualiza la posición de desplazamiento en el estado
		setScrollPosition(0);
	};
	//Ordenamiento
	const [tipoOrdenamiento, setTipoOrdenamiento] = React.useState('');
	//-----------------------------------------------------------------------------

	//Logica, y estados para manejar los filtrados
	const [querys, setQuerys] = React.useState({
		vegan: false,
		marca: '',
		tags: [],
		tags2: [],
	});

	const toggleVegan = () => {
		setQuerys({ ...querys, vegan: !querys.vegan });
		setPaginaActual(1);
		setDesde(0);
		applyFilters();
	};
	const handleMarcaChange = (marca) => {
		setQuerys({ ...querys, marca });
		setPaginaActual(1);
		setDesde(0);
		applyFilters();
	};
	const addTag = (tag) => {
		if (!querys.tags.includes(tag)) {
			const updatedTags = [...querys.tags, tag];
			setQuerys({ ...querys, tags: updatedTags });
			setPaginaActual(1);
			setDesde(0);
			applyFilters();
		}
	};

	const removeTag = (tag) => {
		const updatedTags = querys.tags.filter((selectedTag) => selectedTag !== tag);
		setQuerys({ ...querys, tags: updatedTags });
		setPaginaActual(1);
		setDesde(0);
		applyFilters();
	};

	const addTag2 = (tag2) => {
		if (!querys.tags2.includes(tag2)) {
			const updatedTags2 = [...querys.tags2, tag2];
			setQuerys({ ...querys, tags2: updatedTags2 });
			setPaginaActual(1);
			setDesde(0);
			applyFilters();
		}
	};
	const removeTag2 = (tag2) => {
		const updatedTags2 = querys.tags2.filter((selectedTag2) => selectedTag2 !== tag2);
		setQuerys({ ...querys, tags2: updatedTags2 });
		setPaginaActual(1);
		setDesde(0);
		applyFilters();
	};

	const loadProducts = async (filters) => {
		// setLoader(true)
		const query = {
			...filters,
		};

		let loadedProducts = [];
		if (termino === '') {
			loadedProducts = await getProductsByCategories(id, desde, query);
		} else if (termino?.length > 3) {
			setDesde(0)
			setPaginaActual(1)
			loadedProducts = await searchProducts(id, termino, desde, limit);
		}

		setProducts(loadedProducts);
		setTimeout(() => {
			
			setLoader(false);
		}, 500);
	};

	const applyFilters = async () => {
		setLoader(true);
		// Crea una copia de los filtros actuales
		const query = { ...querys };

		// Resto del código para aplicar los filtros
		if (!query.marca) {
			if (query.vegan) {
				query.vegan = true;
			}
			if (query.tags.length > 0) {
				query.tags = query.tags.join(',');
			}
			if (query.tags2.length > 0) {
				query.tags2 = query.tags2.join(',');
			}
		}

		loadProducts(query);
		setTimeout(() => {
			setLoader(false);
		}, 500);
	};

	const cambiarOrdenamiento = (tipo) => {
		const productosOrdenados = tipo ? ordenarProductos(products.productos, tipo) : [];
		setProducts({ ...products, productos: productosOrdenados });
		setTipoOrdenamiento(tipo || ''); // Establecer tipoOrdenamiento en cadena vacía si se pasa una cadena vacía como tipo
	};

	React.useEffect(() => {
		toast.remove();
		window.scrollTo(0, 0);
		try {
			setLoader(true)
			async function obtenerFiltros() {
				const filtros = await getFilters(id);
				setFiltros(filtros);
			}
			obtenerFiltros();
			setLoader(false)
		} catch (error) {
			console.log(error)
		}
	
	
	}, [id]);

	React.useEffect(() => {
		loadProducts(querys);
	}, [desde, termino, querys]);

	React.useEffect(() => {
		applyFilters();
	}, [querys, desde, id]);

	React.useEffect(() => {
		async function obtenerProductosActualizados() {
			loadProducts({});
		}

		window.addEventListener('actualizar-producto', obtenerProductosActualizados);

		return () => {
			window.removeEventListener('actualizar-producto', obtenerProductosActualizados);
		};
	}, [desde, id]);

	React.useEffect(() => {
		const cargarImagen = async () => {
			const { data } = await axios.get(
				`${process.env.REACT_APP_BACK_URL}/api/imagenes?tipo=FONDO_CATALOGUE`
			);
			setImage(data.imagenes[0]);
			setLoader(false);
		};
		cargarImagen();
	}, [renderKey]);

	React.useEffect(() => {
		console.log(querySub)
		const validateQuery = () =>{

			if (querySub) {
        const decodedSubCategoria = decodeURIComponent(querySub)

			if (decodedSubCategoria === "Aceites" || "Antifaces" || "Esmaltes") {
				setTimeout(() => {
					addTag2(decodedSubCategoria)
					
				}, 1800);
			}
		}
		}
		validateQuery()

	}, [querySub])

	
	React.useEffect(() => {
    const validateQuery = () => {
      if (queryCat) {
        const decodedCategoria = decodeURIComponent(queryCat);
     

        if (decodedCategoria === "Mini Beautiqueras" || "Bags" || "beautiboxes") {
          setTimeout(() => {
            addTag(decodedCategoria);
          }, 1800);
        }
      }
    };

    validateQuery();
  }, [queryCat]);

	React.useEffect(() => {
		const validateQuery = () =>{

			if (searchParam && searchParam !== "") {
				setTimeout(() => {
					setTermino(searchParam)
					
				}, 1000);
			}
		}
		validateQuery()

	}, [])

	React.useEffect(() => {
		const validateQuery = () =>{

			if (marcaParam && marcaParam !== "") {
				setTimeout(() => {
					handleMarcaChange(marcaParam)
					
				}, 1500);
			}
		}
		validateQuery()

	}, [marcaParam])
	
	

	function handleOpenWidget() {
		try {
			const widgetCloudinary = window.cloudinary.createUploadWidget(
				{
					cloudName: 'dlblszxyo',
					uploadPreset: 'laBeautiqueria',
				},
				async (err, result) => {
					if (!err && result && result.event === 'success') {
						const url = result.info.secure_url;

						await axios.put(
							`${process.env.REACT_APP_BACK_URL}/api/imagenes/${image._id}?url=${url}`,
							'',
							config
						);

						setImage({ ...image[0], url });
						setRenderKey((prevKey) => prevKey + 1);
					}
				}
			);

			widgetCloudinary.open();
		} catch (error) {
			toast.error('Problema con la carga de la imagen', error);
		}
	}

	return (
		<div className='fondoCatalogue' 
		style={{
			// backgroundImage: `url(${image?.url})`, 
			backgroundColor:"white"
			}}
		>
			<header className='headerCatalogue'>
				<NavBar />
			</header>
			<main className='catalogueMain'>
				{isSuperAdmin && (
					<>
					
					<div className='fondoPrevButton' onClick={() => handleOpenWidget()}>
						<i className='bi bi-pencil'></i>
						cambiar fondo
					</div>
					</>
				
				)}
				{loader || filtros?.length === 0  ? (
					<Loader />
				) : (
					<>
						<Toaster
							position='bottom-left'
							reverseOrder={false}
							toastOptions={{ className: 'fondoToast' }}
						/>
						<div className='atrasTitle'>
						{/* <button className='fondoPrevButton buttonCata' onClick={()=>navigate(-1)}>
							<ChevronLeftIcon style={{ fill: "white" }} />
							Back
						</button> */}
							<h2 className='container tituloCatalogue'>
								{' '}
								
							</h2>
						</div>
						<div className=' containerSearch'>
							<form className='searchCatalogue form-group has-feedback' role='search'>
								<input
									className='form-control me-2 inputSearchCatalogue'
									type='search'
									placeholder="Buscar producto, marca, etc"
									value={termino}
									onChange={(e) => handleSearch(e)}
								/>
								<i className='bx bx-search form-control-feedback'></i>
							</form>
							<div className='dropContainer' >

								{isMobile && (
										<DropdownButton
										className=' ord'
										id='dropdown-basic-button'
										title={
											<div className='dropTituleCatalogue'>
												<Typography style={{fontFamily:"Montserrat", fontSize:"16px"}} className='bi filterOp'>Ordenar</Typography>
											</div>
										}
									>
										<Dropdown.Item
											className='textDropdownItemCataloque'
											onClick={() => cambiarOrdenamiento('precioMenor')}
										>
											Por precio (menor a mayor)
										</Dropdown.Item>
										<Dropdown.Item
											className='textDropdownItemCataloque'
											onClick={() => cambiarOrdenamiento('precioMayor')}
										>
											Por precio (mayor a menor)
										</Dropdown.Item>
										<Dropdown.Item
											className='textDropdownItemCataloque'
											onClick={() => cambiarOrdenamiento('nombreA')}
										>
											Por nombre (A-Z)
										</Dropdown.Item>
										<Dropdown.Item
											className='textDropdownItemCataloque'
											onClick={() => cambiarOrdenamiento('nombreZ')}
										>
											Por nombre (Z-A)
										</Dropdown.Item>
									</DropdownButton>
																	)}
								{isMobile && (
									<DropdownButton
										className='dropCatalogue mobile.dropdown'
										id='dropdown-basic-button-filters'
										title={
											<div className='dropTituleCatalogue'>
												<Typography style={{fontFamily:"Montserrat", fontSize:"16px"}} className='bi filterOp'>Filtrar</Typography>
											</div>
										}
									>
										{/* Opciones de filtros aquí */}
										{filtros?.vegan?.total > 0 && (
											<div   className='optionContainer'>
												<DropdownItem
												style={{fontFamily:"Montserrat", fontSize:isMobile?"10px":"14px"}}
													className={`filterOption ${querys.vegan ? 'active' : ''}`}
													onClick={toggleVegan}
												>
													Productos veganos ({filtros.vegan[0].total})
												</DropdownItem>
											</div>
										)}
				
										<strong style={{fontFamily:"Montserrat", textAlign:"center"}} className='strongDrop'>Categorías</strong>
										<div className='optionContainer'>
											{filtros?.tags?.sort((a, b) => a.nombre.localeCompare(b.nombre)).filter((t)=>t.nombre !== "").map((tag) => (
												<DropdownItem
												style={{fontFamily:"Montserrat", fontSize:isMobile?"14px":"14px"}}
													key={tag.nombre}
													className={`textDropdownItemCataloque ${
														querys.tags.includes(tag.nombre) ? 'activeFilter' : ''
													}`}
													onClick={() => addTag(tag.nombre)}
												>
											 <Typography  sx={{fontSize:"14px",gap:"5px",display:"flex", alignItems:"center", fontWeight:querys.tags.includes(tag.nombre) && 600 }}>
												{capitalize(tag.nombre)} ({tag.total}) {querys.tags.includes(tag.nombre) ?  <Typography sx={{fontSize:"10px"}}>-Seleccionado-</Typography> : ''}
												</Typography>	
												</DropdownItem>
											))}
										</div>
										<strong style={{fontFamily:"Montserrat", textAlign:"center"}} className='strongDrop'>Sub categorías</strong>
										<div className='optionContainer'>
											{filtros?.tags2?.sort((a, b) => a.nombre.localeCompare(b.nombre)).map((tag2) => (
												<DropdownItem
												style={{fontFamily:"Montserrat", fontSize:isMobile?"14px":"14px"}}
													key={tag2.nombre}
													className={`textDropdownItemCataloque ${
														querys.tags2.includes(tag2.nombre) ? 'activeFilter' : ''
													}`}
													onClick={() => addTag2(tag2.nombre)}
												>

<Typography  sx={{fontSize:"14px",gap:"5px",display:"flex", alignItems:"center", fontWeight:querys.tags2.includes(tag2.nombre) && 600 }}>
												{capitalize(tag2.nombre)} ({tag2.total}) {querys.tags2.includes(tag2.nombre) ?  <Typography sx={{fontSize:"10px"}}>-Seleccionado-</Typography> : ''}
												</Typography>	
													{/* {capitalize(tag2.nombre)} <b>({tag2.total})</b> */}
												</DropdownItem>
											))}
										</div>

										<strong style={{fontFamily:"Montserrat", textAlign:"center"}} className='strongDrop'>Marcas</strong>
										<div className='optionContainer'>
											{filtros?.marcas?.sort((a, b) => a.nombre.localeCompare(b.nombre))
											.map((marca) => (
												<DropdownItem
												style={{fontFamily:"Montserrat", fontSize:isMobile?"14px":"14px"}}
													key={marca.nombre}
													className={`textDropdownItemCataloque  ${
														querys.marca === marca.nombre ? 'activeFilter' : ''
													}`}
													onClick={() => handleMarcaChange(marca.nombre)}
												>
															 <Typography  sx={{fontSize:"14px",gap:"5px",display:"flex", alignItems:"center", fontWeight:	querys.marca === marca.nombre && 600 }}>
												{capitalize(marca.nombre)} ({marca.total}) {	querys.marca === marca.nombre ?  <Typography sx={{fontSize:"10px"}}>-Seleccionado-</Typography> : ''}
												</Typography>	
												</DropdownItem>
											))}
										</div>
									</DropdownButton>
								)}
								
							</div>
							{isMobile &&
								(querys.vegan ||
									querys.marca ||
									querys.tags.length > 0 ||
									querys.tags2.length > 0) && (
									<div>
										<Typography style={{fontFamily:"Montserrat", fontSize:isMobile?"16px":"14px", fontWeight:600}}>Filtros Seleccionados:</Typography>
										{tipoOrdenamiento !== '' && (
											<li  style={{alignItems:"left",fontFamily:"Montserrat", fontSize:isMobile?"12px":"14px"}}>
												Ordenamiento: {tipoOrdenamiento}
												<i

													className='bi bi-x-circle filterOptionX'
													onClick={() => {
														cambiarOrdenamiento(''); // Establecer tipoOrdenamiento en cadena vacía
														loadProducts(querys); // Volver a cargar los productos sin ordenar
													}}
												></i>
											</li>
										)}
										<ul style={{display:"flex", flexDirection:"column", alignItems:"flex-start"}}>
											{querys.marca && (
												<li style={{fontFamily:"Montserrat", fontSize:isMobile?"14px":"14px"}}>
													Marca:<b>{querys.marca}</b>
													<i
														className='bi bi-x-circle filterOptionX'
														onClick={() => handleMarcaChange('')}
													></i>
												</li>
											)}
											{querys.vegan && (
											<li style={{fontFamily:"Montserrat", fontSize:isMobile?"14px":"14px"}}>
													Productos Veganos
													<i className='bi bi-x-circle filterOptionX' onClick={toggleVegan}></i>
												</li>
											)}
											{querys.tags.map((tag) => (
											<li style={{fontFamily:"Montserrat", fontSize:isMobile?"14px":"14px"}}
												key={tag}>
													Categoría:<b> {tag} </b>
													<i
														className='bi bi-x-circle filterOptionX'
														onClick={() => removeTag(tag)}
													></i>
												</li>
											))}
											{querys.tags2.map((tag2) => (
								<li style={{fontFamily:"Montserrat", fontSize:isMobile?"14px":"14px"}}
												key={tag2}>
													Sub categoría: <b>{tag2}</b>
													<i
														className='bi bi-x-circle filterOptionX'
														onClick={() => removeTag2(tag2)}
													></i>
												</li>
											))}
										</ul>
									</div>
								)}
						</div>
						{!isMobile &&
								(querys.vegan ||
									querys.marca ||
									querys.tags.length > 0 ||
									querys.tags2.length > 0) && (
										<div className='selectedFilters'>
											<Typography style={{color:"#fff",fontFamily:"Montserrat", fontSize:isMobile?"12px":"14px"}}>Filtros Seleccionados:</Typography>
											{tipoOrdenamiento !== '' && (
												<li style={{fontFamily:"Montserrat", fontSize:isMobile?"12px":"14px"}}>
													Ordenamiento: {tipoOrdenamiento}
													<i
														className='bi bi-x-circle filterOptionX'
														onClick={() => {
															cambiarOrdenamiento(''); // Establecer tipoOrdenamiento en cadena vacía
															loadProducts(querys); // Volver a cargar los productos sin ordenar
														}}
													></i>
												</li>
											)}
											<ul>
												{querys.marca && (
				<li style={{color:"#fff",fontFamily:"Montserrat", fontSize:isMobile?"12px":"14px"}}>
														Marca: {querys.marca}
														<i
														style={{color:"#fff !important"}}
															className='bi bi-x-circle filterOptionX'
															onClick={() => handleMarcaChange('')}
														></i>
													</li>
												)}
												{querys.vegan && (
												<li style={{color:"#fff",fontFamily:"Montserrat", fontSize:isMobile?"12px":"14px"}}>
														Productos Veganos
														<i style={{color:"#fff !important"}} className='bi bi-x-circle filterOptionX' onClick={toggleVegan}></i>
													</li>
												)}
												{querys.tags.map((tag) => (
												<li style={{color:"#fff",fontFamily:"Montserrat", fontSize:isMobile?"12px":"14px"}}
													key={tag}>
														Categoría: {tag}
														<i
														style={{color:"#fff !important"}}
															className='bi bi-x-circle filterOptionX'
															onClick={() => removeTag(tag)}
														></i>
													</li>
												))}
												{querys.tags2.map((tag2) => (
								<li style={{color:"#fff",fontFamily:"Montserrat", fontSize:isMobile?"12px":"14px"}}
													key={tag2}>
														Sub categoría: {tag2}
														<i
														style={{color:"#fff !important"}}
															className='bi bi-x-circle filterOptionX'
															onClick={() => removeTag2(tag2)}
														></i>
													</li>
												))}
											</ul>
										</div>
									)}
						<div className='containerCatalogueAndFilter'>
							<div className='container containerCatalogue'>
							{termino !== "" && termino?.length <	4  && (
									<div style={{width:"100%", marginBottom:"20px"}}>
										<Typography style={{fontFamily:"Montserrat", fontSize:isMobile?"10px":"14px"}}>Ingresa más caracteres para buscar</Typography>
									</div>
								)
								}
								{termino !== "" && termino?.length >	3 && products?.total !== 0 && (
									<div style={{width:"100%", marginBottom:"20px"}}>
										<Typography style={{fontFamily:"Montserrat", fontSize:isMobile?"10px":"14px"}}>{products?.total} resultado/s para {termino}</Typography>
									</div>
								)
								}
								{
								
								
								(termino === "" && !products.productos?.length) || products.total === 0 ? (
									<div className='divCatalogue px-2 py-2'>
										<Typography style={{fontFamily:"Montserrat", fontSize:isMobile?"10px":"14px"}}>No se encontraron resultados para tu búsqueda o filtrado</Typography>
									</div>
								) : (
									products.productos?.map((producto, index) => {
										return (
											// <div className='divCatalogue px-2 py-2' key={index}>
											// 	<Card sale={producto} />
											// </div>
											<div
												className={`divCatalogue px-2 py-2 ${isMobile ? 'mobile-card' : ''}`}
												key={index}
											>
												<Card sale={producto} isMobile={isMobile} termino={termino} />
											</div>
										);
									})
								)}
							</div>

							{/* Agregamos la sección de filtrado */}
							{!isMobile && (
								<div className='filterSection'>
									<Typography  style={{fontFamily:"Montserrat", fontSize:"16px", fontWeight:600}}>Filtrar:</Typography>
									{filtros && (
										<div className='filterOptions'>
											{filtros.vegan?.total > 0 && (
												<Typography
												style={{fontFamily:"Montserrat", fontSize:isMobile?"10px":"14px"}}
													className={`filterOption ${querys.vegan ? 'active' : ''}`}
													onClick={toggleVegan}
												>
													Productos veganos ({filtros.vegan[0].total})
												</Typography>
											)}
							
											{/* Tags */}
											<strong>Categorías</strong>
											<div className='filterOptionContainer'>
											{filtros.tags?.sort((a,b)=>a.nombre.localeCompare(b.nombre)).filter((t)=>t.nombre !== "").map((tag) => (
												<Typography style={{fontFamily:"Montserrat", fontSize:isMobile?"10px":"14px"}}
													key={tag.nombre}
													className={`filterOption ${
														querys.tags.includes(tag.nombre) ? 'active' : ''
													}`}
													onClick={() => addTag(tag.nombre)}
												>
													{capitalize(tag.nombre)} ({tag.total})
												</Typography>
											))}
											</div>
											{/* Tags2 */}
											<strong>Sub categorías</strong>
											<div className='filterOptionContainer'>
											{filtros.tags2.sort((a,b)=>a.nombre.localeCompare(b.nombre)).filter((t)=>t.nombre !== "").map((tag2) => (
												<Typography
												style={{fontFamily:"Montserrat", fontSize:isMobile?"10px":"14px"}}
													key={tag2.nombre}
													className={`filterOption ${
														querys.tags2.includes(tag2.nombre) ? 'active' : ''
													}`}
													onClick={() => addTag2(tag2.nombre)}
												>
													{capitalize(tag2.nombre)} ({tag2.total})
												</Typography>
											))}
											</div>

															{/* Marcas */}
															<strong>Marcas</strong>
											<div className='filterOptionContainer'>
																							
											{filtros?.marcas?.sort((a, b) => a.nombre.localeCompare(b.nombre))
												.map((marca) => (
												<Typography
												style={{fontFamily:"Montserrat", fontSize:isMobile?"10px":"14px"}}
													key={marca.nombre}
													className={`filterOption ${
														querys.marca === marca.nombre ? 'active' : ''
													}`}
													onClick={() => handleMarcaChange(marca.nombre)}
												>
													{marca.nombre !== '' ? marca.nombre : 'Sin marca'} ({marca.total})
												</Typography>
											))}
											</div>
										</div>
									)}
									{/* Lista de filtros seleccionados */}
									{!isMobile && (
										<DropdownButton
										style={{background:"transparent", marginTop: "10px"}}
										className=' ord'
										id='dropdown-basic-button'
										title={
											<div className='dropTituleCatalogue'>
												<Typography style={{fontFamily:"Montserrat", fontSize:"14px"}} className='bi filterOp'>Ordenar</Typography>
											</div>
										}
									>
										<Dropdown.Item
											className='textDropdownItemCataloque'
											onClick={() => cambiarOrdenamiento('precioMenor')}
										>
											Por precio (menor a mayor)
										</Dropdown.Item>
										<Dropdown.Item
											className='textDropdownItemCataloque'
											onClick={() => cambiarOrdenamiento('precioMayor')}
										>
											Por precio (mayor a menor)
										</Dropdown.Item>
										<Dropdown.Item
											className='textDropdownItemCataloque'
											onClick={() => cambiarOrdenamiento('nombreA')}
										>
											Por nombre (A-Z)
										</Dropdown.Item>
										<Dropdown.Item
											className='textDropdownItemCataloque'
											onClick={() => cambiarOrdenamiento('nombreZ')}
										>
											Por nombre (Z-A)
										</Dropdown.Item>
									</DropdownButton>
																	)}
								
								</div>
							)}
						</div>
						<>
							<Pagination
								key={paginaActual}
								productosPorPagina={productosPorPagina}
								productos={products.total}
								paginado={paginado}
								paginaActual={paginaActual}
							/>
						</>
						{/* {!isMobile && <GloboContacto posicion={'derechaButton'} />} */}
					</>
				)}
			</main>
			<footer>
				<Footer />
			</footer>
		</div>
	);
}

export default Catalogue;
