import React from 'react';
import axios from 'axios';
import Cart from '../../routes/cart/Cart';
import { setUserInfo } from '../../features-slice/user/userSlice';
import { DropdownButton, Dropdown, Navbar, Nav } from 'react-bootstrap';
import { logout } from '../../utils/auth/auth';
import { clearInfo } from '../../features-slice/user/userSlice';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { isAuthenticated } from '../../utils/auth/auth';
import logo from '../../assets/logoheader.png';
import bolsita from '../../assets/BOLSA_CARRITO_GRIS.png';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './NavBar.Module.css';
import { agregarAlCarrito } from '../../features-slice/cart/carritoSlice';
import { decodificarToken } from '../../utils/cart/cart';
import { getSales } from '../../utils/products/products';
import { isEditMode } from '../../utils/users/users';
import { useMediaQuery } from 'react-responsive';
import christmas from "../../assets/images/beautisale.jpg"
import MenuIcon from '@mui/icons-material/Menu';
import Carousel from 'react-multi-carousel';
import { Typography, Box } from '@mui/material';







function NavBar({ tipoUsuario, nombre, puntos }) {
	const bannerText = ["APROVECHA NUESTRAS BEAUTIGANGAS", "CONOCE NUESTRAS PROMOS BANCARIAS","DESCUBRI NUESTRO BEST SELLER DEL MES", "HASTA 3 CUOTAS SIN INTERES"]
		const [currentBanner, setCurrentBanner] = React.useState(0)
	const carrito = useSelector((state) => state.carrito);
		const isMobile = useMediaQuery({ maxWidth: 700 });
	const user = useSelector((state) => state.user.userInfo);
	const idUsuario = localStorage.getItem('Usuario');
	const dispatch = useDispatch();
	const navigate = useNavigate()

	const [sales, setSales] = React.useState([])

	const [userLoading, setUserLoading] = React.useState(true);
	const isMobileScreen = useMediaQuery({ maxWidth: 800 }); // Cambio de imagen en pantallas pequeñas

	// const uri = process.env.REACT_APP_BACK_URL || 'http://localhost:8080';
	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1,
			slidesToSlide: 1,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1,
			slidesToSlide: 1,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1,
		},
	};
	const titleDrop = () => {
		if (user.role !== 'ADMIN_ROLE' && user.role !== 'SUPERADMIN_ROLE') {
			return (
				<div className='dropdowncito2'>
					<i className='bi bi-gem'></i>
					{user?.puntosAcumulados === null ? '0' : `${user.puntosAcumulados}`}
				</div>
			);
		} else {
			return (
				<div className=' dropdowncito '>
					<p className='titleDrop'>ADMIN</p>
				</div>
			);
		}
	};

	React.useEffect(() => {
		if (isAuthenticated() && !user.role) {
			async function recargarEstado() {
				try {
					const userQuery = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/usuarios/${idUsuario}`);
					dispatch(setUserInfo(userQuery.data));
				} catch (error) {
					console.log(error.response.data?.errors[0].msg);
				} finally {
					setUserLoading(false); // Marca que la carga ha finalizado, independientemente del resultado
				}
			}
			recargarEstado();
		}
	}, [user]);

	function comprobarToken() {
		const carritoToken = localStorage.getItem('cc');
		if (carritoToken) {
			const carritoDecodificado = decodificarToken();

			carritoDecodificado.forEach((item) => {
				dispatch(agregarAlCarrito(item));
			});
		}
	}

	React.useEffect(() => {
		if (!isAuthenticated()) {
			logout();
		}
		comprobarToken();
	}, [carrito]);

	const cerrarSesion = (e) => {
		e.preventDefault();

		window.location.replace('/');
		setTimeout(() => {
			dispatch(clearInfo());
		}, 1500);
		logout();
	};

	const [showCart, setShowCart] = React.useState(false);

	const handleCloseCart = () => {
		setShowCart(false);
	};

	const handleOpenCart = () => {
		setShowCart(true);
	};
	React.useEffect(() => {	
		async function getOfertas() {
			setSales(await getSales());			
		}
		getOfertas();
		window.addEventListener('actualizar-producto', getOfertas);
		return () => {
			window.removeEventListener('actualizar-producto', getOfertas);
		};
	}, []);

	// React.useEffect(() => {
	// 	const isEdit = async () => {
	// 		const isAuthenticatedUser = isAuthenticated();
	// 		const rta = await isEditMode();
	
	// 		if (
	// 			(!isAuthenticatedUser && rta) ||
	// 			(isAuthenticatedUser && !userLoading && user && user.role !== "SUPERADMIN_ROLE" && user.role !== "ADMIN_ROLE")
	// 		) {
	// 			navigate("/sitioenmantenimiento");
	// 		}
	// 	};
	
	// 	// Verifica si el usuario está definido antes de llamar a isEdit
	// 	if (user === undefined) {
	// 		// Puedes esperar a que el usuario se cargue antes de ejecutar la lógica
	// 		const waitForUser = setInterval(() => {
	// 			if (user !== undefined) {
	// 				clearInterval(waitForUser);
	// 				isEdit();
	// 			}
	// 		}, 100);
	// 	} else {
	// 		// Si el usuario ya está cargado, ejecuta la lógica directamente
	// 		isEdit();
	// 	}
	// }, [user, userLoading, navigate]);
	// ;
	
	

	return (
<Box style={{display:"flex", flexDirection:"column", position:"fixed", zIndex:2000000}} > 
		<Box style={{height:"43px",width:"100vw", marginTop:"0px"}}> 
		{/* <Typography  key={currentBanner} className="text-enter" style={{ color: "#20563f", fontSize:!isMobile? "25px":"16px", fontFamily:"Nexa" }}>
				{bannerText[currentBanner]}
		</Typography> */}
			<Carousel
			draggable={true}
			showDots={false}
			swipeable={true}
			responsive={responsive}
			infinite={true}
			autoPlay={true}
			keyBoardControl={true}
			autoPlaySpeed={3000}
			shouldResetAutoplay={false}
			pauseOnHover={false}
			removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
		>
			{bannerText?.map((texto, index) => (
				<Box
					key={index}
					sx={{
						height:"45px",
						display:"flex",
						justifyContent:"center",
						alignItems:"center",
						backgroundColor:"#E9CED2",
						zIndex:2,
						top:"-5px",
						color: "#565862",
						fontWeight:700,
						fontSize: !isMobile ? "16px" : "12px",
						fontFamily: "poppins",
						textAlign: "center"
					}}
				>
					{texto}
				</Box>
			))}
		</Carousel>
	</Box>
		<div className='navbar navbar-expand-md border-bottom divNavBar' style={{ zIndex: 2000000 }}>
			<Cart handleCloseCart={handleCloseCart} handleOpenCart={handleOpenCart} showCart={showCart} />
			<Navbar
				// bg='light'
				expand='lg'
				className='container-fluid justify-content-space-between navbaaar'
				style={{ zIndex: 4000000,  }}
			>
				<Navbar.Brand href='/markethome' className='logo mr-auto'>
					<img src={logo} alt='Logo LABeautiqueria Market' />
				</Navbar.Brand>
				{isMobileScreen && (
                        <Navbar.Toggle className='border-0 ml-auto' style={{backgroundColor:"white"}}>
                            <MenuIcon style={{fontSize: '30px', color: '#565862' }} />
                        </Navbar.Toggle>
                    )}
				{/* <Navbar.Toggle  	style={{marginBottom:isMobileScreen&& "10px"}}className='custom-toggle-image' aria-controls='basic-navbar-nav' /> */}
				<Navbar.Collapse id='basic-navbar-nav'>
					<Nav className='ml-auto nav nav-pills navbar-nav me-auto mb-2 mb-md-0 derecha'>
						<section style={{display:"flex", justifyContent:"space-around", marginLeft:!isMobileScreen?"35%":"", gap:"25px"}}>
						{/* <section style={{display:"flex", justifyContent:"flexEnd", gap:"0.5vw"}}> */}
							{/* <Link style={{fontSize:"14px", font:"Poppins",color:"#565862", textDecoration:"none"}} to='/' className='linkNavBarMarket'>
								Home
							</Link> */}
							<Link style={{fontSize:"14px", color:"#565862", textDecoration:"none"}}  to='/markethome' className='linkNavBarMarket'>
								Inicio 
							</Link>
							<Link style={{fontSize:"14px", color:"#565862", textDecoration:"none"}}  to='/market/categoria/64ef4986d269731b208134bf?nombre=MARKET' className='linkNavBarMarket'>
								Shop
							</Link>
							{sales?.productos?.length > 0 && (
								<Link style={{fontSize:"14px", color:"#565862", textDecoration:"none"}}  to='/ofertas' className='linkNavBarMarket'>
									Gangas
								{/* #BEAUTI CHRISTMAS */}
								{/* <img className='christmasNav' src={christmas} alt='Navidad' /> */}
							</Link>
							)}						
							{/* <Link style={{fontSize:"14px"}} to='/alianzas' className='nav-link linkNavBar'>
								Alianzas
							</Link> */}
							{/* <Link to='/contacto' className='nav-link linkNavBar'>
								Contacto
							</Link> */}
							<div className='nav-link linkNavBar bolsita'>
								<img
									src={bolsita}
									onClick={handleOpenCart}
									alt='bolsita'
									style={{ width: '60px', padding: '0 5px' }}
								></img>
								{carrito?.length ? <span className='enCarrito badge'>{carrito?.length}</span> : ''}
							

							</div>
							{user.role !== undefined ? (
								<DropdownButton
									className='dropdowncito '
									id='dropdown-basic-button'
									title={titleDrop()}
								>
									<div className='container-items'>
										{(user.role === 'ADMIN_ROLE' || user.role === 'SUPERADMIN_ROLE') && (
											// <Dropdown.Item>
											<Link className='link-drop' to='/dashboard'>
												Dashboard
											</Link>

											// </Dropdown.Item>
										)}
										{user.role === 'SUPERADMIN_ROLE' && (
											// <Dropdown.Item>
											<Link className='link-drop' to='/stats'>
												Estadisticas
											</Link>

											// </Dropdown.Item>
										)}
										{(user.role === 'ADMIN_ROLE' || user.role === 'SUPERADMIN_ROLE') && (
											// <Dropdown.Item>
											<Link className='link-drop' to='/cajadiaria'>
												Caja diaria
											</Link>

											// </Dropdown.Item>
										)}
										{(user.role === 'ADMIN_ROLE' || user.role === 'SUPERADMIN_ROLE') && (
											// <Dropdown.Item>
											<Link className='link-drop' to='/iniciarSesion'>
												Pantalla Login
											</Link>

											// </Dropdown.Item>
										)}
										{/* <Dropdown.Item> */}
										<Link className='link-drop' to='/perfil'>
											Mi perfil
										</Link>
										{/* </Dropdown.Item> */}
										<Dropdown.Item
											className='link-drop'
											href='#action/2'
											onClick={(e) => cerrarSesion(e)}
										>
											Cerrar sesión
										</Dropdown.Item>
									</div>
								</DropdownButton>
							) : (
								<div className='iniciarSesion'>
									<Link to={'/iniciarSesion'} className='iniciarSesionLink'>
										<button className='btn btn-sm btn-outline-secondary iniciarSesionButton'>
											Iniciar Sesión
										</button>
									</Link>
								</div>
							)}
						{/* </section>
						<section> */}
							
						</section>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</div>
		</Box>
	);
}

export default NavBar;
